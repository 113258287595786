import { EntityResource, EntityType, ToggleFilterType } from '@app/store/filters/models/filters.model';
import { selectFiltersState } from '@app/store/filters/selectors/filters.selectors';
import { createSelector } from '@ngrx/store';

export const selectFilterChipLabels = createSelector(selectFiltersState, state => {
  const { filter, companies, divisions, zone } = state;
  // extract strings from each property array in filter state
  const chips: EntityResource[] = [];
  // if defaults are loading don't display chips
  // if (filter.default) {
  //   return chips;
  // }
  // toggle filters
  if (filter.powerOn) {
    chips.push({ id: ToggleFilterType.POWER_ON, name: ToggleFilterType.POWER_ON, type: EntityType.TOGGLE });
  }
  // accounts/divisions
  // add account chip only if there are >1 company (have read:companies perm) OR 1 company and >1 division (customer admin or super user)
  if (companies.length > 1 || (companies.length === 1 && divisions.length > 1)) {
    filter.divisions.forEach(d => {
      chips.push(d);
    });
  }
  // locations
  filter.locations?.forEach(l => {
    chips.push(l);
  });

  // driver
  if (filter?.driver?.id) {
    chips.push(filter.driver);
  }

  // searchTerms
  if (filter?.searchTerms) {
    filter.searchTerms.forEach(st => {
      // always put searchTerms at the beginning of chips list
      chips.unshift({ id: st, name: st, type: EntityType.SEARCH_TERM });
    });
  }

  if (filter.zone) {
    chips.push({ id: zone.id, name: zone.name, type: EntityType.ZONE });
  }

  return chips;
});

export const selectShowFilterChipsForLimitedUser = createSelector(
  selectFiltersState,
  state => state.showFilterChipsForLimitedUser
);

import { AssetFilterSelectionModel } from '@zonar-ui/filter';

export interface FiltersBarSelections {
  asset_filter?: AssetFilterSelectionModel;
  zone_filter?: {
    id: string;
    name: string;
  };
  power_status?: {
    selected: boolean[]; // [true, false] if both selected, but in this case isAllSelected should also be true
    isAllSelected: boolean;
  };
}

export type SortOrder = 'asc' | 'desc';
export enum SortAttribute {
  ASSET_NAME = 'assetName',
  DRIVER_NAME = 'driverName',
  UPDATES = 'geoEventTs'
}

export interface FilterLatLng {
  0: number; // latitude
  1: number; // longitude
}

export interface FiltersState {
  powerOn: boolean;
  companyId: string;
  assetIds: string[];
  divisionIds: string[];
  searchTerms: string[];
  // For now, it is better to keep only the geojson for location filtering.
  // If we need other zone(s) metadata in the future, this may need modified / additional filter properties in this interface
  geojson: string;
  sortOrder: SortOrder;
  sortAttribute: SortAttribute;
  northEast: FilterLatLng;
  southWest: FilterLatLng;
}

export const initialFiltersState: FiltersState = {
  powerOn: null,
  companyId: null,
  assetIds: [],
  divisionIds: [],
  searchTerms: [],
  geojson: null,
  sortOrder: null,
  sortAttribute: null,
  northEast: null,
  southWest: null
};

export const mockFiltersState = { ...initialFiltersState, companyId: 'foo' };

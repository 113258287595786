<div *ngIf="(locationFacade.getIsMobile() | async) === true; then  mobileBlock else desktopBlock;"></div>
<ng-template #mobileBlock>
  <app-mobile-location-container
      (handleOpenFilterDialog)="openFilterDialog(true)"
  ></app-mobile-location-container>
</ng-template>

<ng-template #desktopBlock>
  <app-desktop-location-container></app-desktop-location-container>
</ng-template>


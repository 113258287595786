
<div fxLayout="column" fxFill>
    <ng-container *ngIf="(locationFacade.getIsMobile() | async) === false && !isAssetDetailsView"> 
        <app-filters-bar-container></app-filters-bar-container>
    </ng-container>
    <mat-sidenav-container hasBackdrop="false" fxFlex="100" >
        <mat-sidenav #sidenav opened="true" (openedChange)="locationFacade.setViewPaneOpen($event)">
            <div fxLayout="column" fxFill>
                <ng-content></ng-content>
            </div>
        </mat-sidenav>

        <mat-sidenav-content fxLayout="column" fxLayoutAlign="space-between">
            <app-sidenav-toggle-btn (click)="sidenav.toggle()" [offset]="sidenav.opened ? sidenav._getWidth() : 0">
            </app-sidenav-toggle-btn>

            <div class="desktop-layout-container" fxFlex>
                <div class="content-pane map-pane">
                    <ng-template appSharedMapSingleton></ng-template>
                </div>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable security/detect-object-injection */
// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Zone, ZoneStatus, Category, Resources, ZoneApiParams } from '../zones.model';
import { Observable, switchMap, of, tap, concat, filter, first, map, combineLatest, mergeMap, zip } from 'rxjs';
import { DataDogService } from '@app/services/data-dog.service';
import { PermissionsService } from '@zonar-ui/auth';

@Injectable({
  providedIn: 'root'
})
export class ZonesService {
  constructor(
    private http: HttpClient,
    private datadogService: DataDogService,
    private permsService: PermissionsService
  ) {}

  zoneCache = {};
  categoryCache = {};
  zonesWithCategoriesCache = {};
  url = `${environment.coreEntityApiBase.url}`;
  perPage = 500;
  expectedZonesTotal: number = 0;
  expectedCategoriesTotal: number = 0;
  pageNumberResponseHeader = 'X-Page-Count';
  totalCountResponseHeader = 'X-Total-Count';
  divisions: string[];
  categorySearchQuery = 'visible:true';

  getCategories(params: ZoneApiParams, companyId: string): Observable<Category[]> {
    let numPages: number;
    const httpParams: HttpParams = new HttpParams({
      fromObject: { ...params, q: this.categorySearchQuery, status: ZoneStatus.ACTIVE, per_page: this.perPage, page: 1 }
    });

    return this.http.get(`${this.url}/categories`, { params: httpParams, observe: 'response' }).pipe(
      tap(categoriesHttpResponse => {
        numPages = parseInt(categoriesHttpResponse.headers.get(this.pageNumberResponseHeader));

        this.setExpectedResourceTotal(
          params,
          parseInt(categoriesHttpResponse.headers.get(this.totalCountResponseHeader)),
          Resources.CATEGORIES
        );

        this.categoryCache[companyId] = categoriesHttpResponse.body as Category[];
      }),
      switchMap(_ => {
        let logMsg = 'ZONES_CHECKBOX_DEBUG: #getCategories categories loaded';
        if (numPages > 1) {
          const params = {
            companyId
          };
          return this.getSubsequentPages(numPages, Resources.CATEGORIES, {
            ...params,
            q: this.categorySearchQuery
          }).pipe(
            tap(categories => {
              this.categoryCache[companyId] = [...this.categoryCache[companyId], ...categories];
            }),
            map(_ => {
              logMsg = `${logMsg} :numPages > 1`;

              this.datadogService.log(logMsg);
              return this.categoryCache[companyId];
            })
          );
        }

        return of(this.categoryCache[companyId]);
      })
    );
  }

  getResourcesByPage(pageNumber: number, resource: Resources, params: ZoneApiParams): Observable<Zone[] | Category[]> {
    const httpParams = new HttpParams({
      fromObject: {
        ...params,
        status: ZoneStatus.ACTIVE,
        per_page: params.per_page || this.perPage,
        page: pageNumber
      }
    });
    return this.http.get(`${this.url}/${resource}`, { params: httpParams }) as Observable<Zone[] | Category[]>;
  }

  getSubsequentPages(numPages: number, resource: Resources, params: ZoneApiParams): Observable<Zone[] | Category[]> {
    const pageCalls = [];
    // we'll have already gotten page 1 results with the first call, so we start at 2
    for (let i = 2; i <= numPages; i++) {
      pageCalls.push(this.getResourcesByPage(i, resource, params).pipe(first()));
    }

    return concat(...pageCalls) as Observable<Zone[] | Category[]>;
  }

  getZones(params: ZoneApiParams, companyId: string): Observable<Zone[]> {
    let numPages: number;
    const httpParams: HttpParams = new HttpParams({
      fromObject: { ...params, status: ZoneStatus.ACTIVE, per_page: this.perPage, page: 1 }
    });

    return this.http.get(`${this.url}/zones`, { params: httpParams, observe: 'response' }).pipe(
      tap(zonesHttpResponse => {
        numPages = parseInt(zonesHttpResponse.headers.get(this.pageNumberResponseHeader));

        this.setExpectedResourceTotal(
          params,
          parseInt(zonesHttpResponse.headers.get(this.totalCountResponseHeader)),
          Resources.ZONES
        );
        this.zoneCache[companyId] = zonesHttpResponse.body;
      }),
      switchMap(_ => {
        let logMsg = 'ZONES_CHECKBOX_DEBUG: #getZones zones loaded';

        if (numPages > 1) {
          const params = {
            companyId
          };
          return this.getSubsequentPages(numPages, Resources.ZONES, params).pipe(
            tap(zones => {
              this.zoneCache[companyId] = [...this.zoneCache[companyId], ...zones];
            }),
            map(_ => {
              logMsg = `${logMsg} :numPages > 1`;

              this.datadogService.log(logMsg);
              return this.zoneCache[companyId];
            })
          );
        }

        return of(this.zoneCache[companyId]);
      })
    );
  }

  getResourceById(id: string, resource: Resources) {
    return this.http.get(`${this.url}/${resource}/${id}`);
  }

  getZonesBySearchTerms(params: ZoneApiParams): Observable<Zone[]> {
    if (!this.zonesWithCategoriesCache[params.companyId]) {
      this.zonesWithCategoriesCache[params.companyId] = {};
    }
    if (this.zonesWithCategoriesCache[params.companyId][params.q]) {
      return of(this.zonesWithCategoriesCache[params.companyId][params.q]);
    }
    return combineLatest([this.getResourcesByPage(1, Resources.ZONES, params), this.getDivisionIds()]).pipe(
      mergeMap(([zones, divisions]) => {
        this.zonesWithCategoriesCache[params.companyId][params.q] = zones;

        if (!zones.length || !divisions.length) {
          return of(zones as Zone[]);
        }

        // we still need to filter zones by a user's divisions, so the below is processing all that information
        const categoryIds = (zones as Zone[]).reduce((acc, z) => {
          if (!acc.includes(z.categoryId)) {
            acc.push(z.categoryId);
          }
          return acc;
        }, []);

        const zonesCategoryFetches = categoryIds.map(categoryId => {
          return this.getResourceById(categoryId, Resources.CATEGORIES);
        });

        return zip(...zonesCategoryFetches).pipe(
          map(categories => {
            const mergedResult = this.mergeResult(zones as Zone[], categories as Category[]);

            const authorizedZones = [];

            mergedResult.forEach(z => {
              z.category.divisions.forEach(d => {
                if (divisions.includes(d)) {
                  authorizedZones.push(z);
                }
              });
            });

            this.zonesWithCategoriesCache[params.companyId][params.q] = authorizedZones;
            return authorizedZones as Zone[];
          })
        );
      })
    );
  }

  getZonesWithCategories(companyId: string): Observable<Zone[]> {
    // fetching divisions observable first ensures we get ALL possible divisions for a div locked user since they come in asyncronously
    return this.getDivisionIds().pipe(
      switchMap(divisionIds => {
        return this._getZonesWithCategories(divisionIds, companyId);
      })
    );
  }

  setExpectedResourceTotal(
    params: { divisionId?: string; categoryId?: string; companyId?: string },
    givenResourceTotal: number,
    resource: Resources
  ) {
    if (params.divisionId || params.categoryId) {
      if (resource === Resources.CATEGORIES) {
        this.expectedCategoriesTotal += givenResourceTotal;
      } else {
        this.expectedZonesTotal += givenResourceTotal;
      }
    } else {
      if (resource === Resources.CATEGORIES) {
        this.expectedCategoriesTotal = givenResourceTotal;
      } else {
        this.expectedZonesTotal = givenResourceTotal;
      }
    }
  }

  _getZonesWithCategories(divisions: string[], companyId: string) {
    let allCategories = [];
    let allZones = [];

    return concat(
      this.getCategories({ companyId }, companyId).pipe(
        tap(categories => {
          allCategories = categories;
        })
      ),
      this.getZones({ companyId }, companyId).pipe(
        tap(zones => {
          allZones = zones;
        })
      )
    ).pipe(
      // we wait until we get all categories to move on, in case the categories API call also returns multiple pages of data (appears rare for most customers but is applicable to big companies such as First Student)
      // we need all categories first because they contain the color value for the zone
      // we wait until zones are batched together to send them all over at once
      filter(() => {
        const logMsg = `ZONES_CHECKBOX_DEBUG: #_getZonesWithCategories allCategories loaded? : ${
          allCategories.length === this.expectedCategoriesTotal
        }`;
        return allCategories.length === this.expectedCategoriesTotal;
      }),
      switchMap(_ => {
        let mergedResult;
        if (divisions.length) {
          mergedResult = this.mergeResult(allZones, allCategories).filter(z => {
            let isIncluded = false;

            if (z.category?.divisions.length) {
              for (let i = 0; i < z.category.divisions.length; i++) {
                if (divisions.includes(z.category.divisions[i])) {
                  isIncluded = true;
                  break;
                }
              }
            }
            return isIncluded;
          });
        } else {
          mergedResult = this.mergeResult(allZones, allCategories);
        }

        this.zonesWithCategoriesCache[companyId] = mergedResult;
        return of(mergedResult);
      })
    );
  }

  mergeResult(zones: Zone[], categories: Category[]): Zone[] {
    return zones
      .map(z => {
        return {
          ...z,
          category: categories.find(c => c.id === z.categoryId)
        };
        // for zonar users, category might be undefined since we're only fetching visible categories, but we still get all zones, so we only want to include visible zones
      })
      .filter(z => z.category);
  }

  getDivisionIds(): Observable<string[]> {
    return combineLatest([this.permsService.getIsPermissionsLoaded(), this.permsService.getDivisionMap()]).pipe(
      filter(([permsLoaded, divMap]) => {
        const rval = permsLoaded && !!Object.keys(divMap).length;
        return rval;
      }),
      mergeMap(([_, divisionMap]) => {
        const divisionIds: string[] = [];

        Object.keys(divisionMap).forEach(divId => {
          if (divisionIds.indexOf(divId) === -1) {
            divisionIds.push(divId);
          }
          if (
            divisionMap[divId].type === 'LEGACY_LOCATION' &&
            divisionMap[divId].parentId &&
            divisionIds.indexOf(divisionMap[divId].parentId) === -1
          ) {
            divisionIds.push(divisionMap[divId].parentId);
          }
        });

        return of(divisionIds);
      })
    );
  }
}

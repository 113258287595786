// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Zonar UI
import { ZonarUiIconModule } from '@zonar-ui/icon';
import { ZonarUiLozengeModule } from '@zonar-ui/lozenge';
import { ZonarUIMaterialModule } from '@zonar-ui/material';
import { ZonarUiProgressSpinnerModule } from '@zonar-ui/progress-spinner';
import { ZonarUiSearchableDropdownModule } from '@zonar-ui/searchable-dropdown';

// NGX & Leaflet
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxLeafletFullscreenModule } from '@runette/ngx-leaflet-fullscreen';
import { NgxLeafletLocateModule } from '@runette/ngx-leaflet-locate';

// App
import { AssetListItemSkeletonComponent } from '@app/modules/shared/components/asset-list-item-skeleton/asset-list-item-skeleton.component';
import { AssetListComponent } from '@app/modules/shared/components/asset-list/asset-list.component';
import { MapHeaderComponent } from '@app/modules/shared/components/map-header/map-header.component';
import { MapComponent } from '@app/modules/shared/components/map/map.component';
import { FilterDialogComponent } from '@app/modules/shared/components/filter-dialog/filter-dialog.component';
import { NearbyAssetsListComponent } from '@app/modules/shared/components/nearby-assets-list/nearby-assets-list.component';

import { AssetListItemComponent } from '@app/modules/shared/components/asset-list-item/asset-list-item.component';
import { FilterChipListComponent } from '@app/modules/shared/components/filter-chip-list/filter-chip-list.component';
import { ProgressSpinnerComponent } from '@app/modules/shared/components/progress-spinner/progress-spinner.component';

import { ChipSelectionListComponent } from '@app/modules/shared/components/chip-selection-list/chip-selection-list.component';
import { ToggleFiltersComponent } from './components/toggle-filters/toggle-filters.component';
import { OverlayComponent } from '@app/modules/shared/components/overlay/overlay.component';
import { AssetIoModule } from '@app/modules/asset-io/asset-io.module';
import { IncidentControlComponent } from '@app/modules/shared/components/map/controls/incident-control/incident-control.component';
import { SearchControlComponent } from './components/map/controls/search-control/search-control.component';
import { TimelineControlComponent } from '@app/modules/shared/components/timeline-control/timeline-control.component';

import { AddressListItemSkeletonComponent } from './components/address-list-item-skeleton/address-list-item-skeleton.component';
import { DriverModule } from '@app/modules/driver/driver.module';

import { ViewSwitcherComponent } from '@app/modules/shared/components/view-switcher/view-switcher.component';
import { SharedMapComponent } from '@app/modules/shared-map/shared-map/shared-map.component';
import { SharedMapDirective } from '@app/modules/shared-map/directives/shared-map.directive';
import { MapFilterChipListComponent } from './components/map-filter-chip-list/map-filter-chip-list.component';
import { AssetSearchFormComponent } from './components/asset-search-form/asset-search-form.component';
import { SelectedAssetNavigationComponent } from './components/selected-asset-navigation/selected-asset-navigation.component';
import { EventsModule } from '@app/modules/events/events.module';
import { FeatureTogglesModule } from '@app/modules/feature-toggles/feature-toggles.module';
import { TranslateModule } from '@ngx-translate/core';
import { AssetIoSummaryComponent } from '@app/modules/shared/components/asset-io-summary/asset-io-summary.component';
import { ExpandingDetailsComponent } from '@app/modules/shared/components/expanding-details/expanding-details.component';
import { OpenEventsContainerComponent } from '@app/modules/shared/components/open-events-container/open-events-container.component';
import { PipesModule } from '@app/modules/pipes/pipes.module';
import { MapOptionsComponent } from './components/map/controls/map-options/map-options.component';
import { MapOptionsMenuComponent } from './components/map/controls/map-options-menu/map-options-menu.component';
import { ZoneFilterComponent as ZoneUiFilterComponent, ZuiFiltersBarComponent } from '@zonar-ui/filter';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AssetListInfoComponent } from '@app/modules/shared/components/asset-list-info/asset-list-info.component';
import { DialogComponent } from '@app/modules/shared/components/dialog/dialog.component';
import { FiltersBarContainerComponent } from './components/filters-bar-container/filters-bar-container.component';

@NgModule({
  declarations: [
    AddressListItemSkeletonComponent,
    AssetListComponent,
    AssetListItemComponent,
    AssetListItemSkeletonComponent,
    AssetSearchFormComponent,
    ChipSelectionListComponent,
    FilterChipListComponent,
    FilterDialogComponent,
    MapComponent,
    MapFilterChipListComponent,
    MapHeaderComponent,
    MapOptionsComponent,
    MapOptionsMenuComponent,
    NearbyAssetsListComponent,
    OverlayComponent,
    ProgressSpinnerComponent,
    IncidentControlComponent,
    SearchControlComponent,
    SelectedAssetNavigationComponent,
    SharedMapComponent,
    SharedMapDirective,
    TimelineControlComponent,
    ToggleFiltersComponent,
    ViewSwitcherComponent,
    AssetIoSummaryComponent,
    OpenEventsContainerComponent,
    ExpandingDetailsComponent,
    FiltersBarContainerComponent
  ],
  imports: [
    AssetListInfoComponent,
    DialogComponent,
    AssetIoModule,
    EventsModule,
    CommonModule,
    LeafletModule,
    FlexLayoutModule,
    NgxLeafletFullscreenModule,
    NgxLeafletLocateModule,
    FormsModule,
    ReactiveFormsModule,
    ZonarUIMaterialModule,
    ZonarUiIconModule,
    ZonarUiProgressSpinnerModule,
    ZonarUiSearchableDropdownModule,
    ZonarUiLozengeModule,
    NgxSkeletonLoaderModule,
    DriverModule,
    RouterModule,
    FeatureTogglesModule,
    TranslateModule,
    PipesModule,
    ZoneUiFilterComponent,
    InfiniteScrollModule,
    FlexLayoutModule,
    ZuiFiltersBarComponent
  ],
  exports: [
    AddressListItemSkeletonComponent,
    AssetListComponent,
    AssetListItemComponent,
    FilterChipListComponent,
    FilterDialogComponent,
    MapComponent,
    MapHeaderComponent,
    NearbyAssetsListComponent,
    OverlayComponent,
    ProgressSpinnerComponent,
    IncidentControlComponent,
    SearchControlComponent,
    SharedMapComponent,
    SharedMapDirective,
    TimelineControlComponent,
    ViewSwitcherComponent,
    AssetIoSummaryComponent,
    OpenEventsContainerComponent,
    ExpandingDetailsComponent,
    MapOptionsComponent,
    MapOptionsMenuComponent,
    FiltersBarContainerComponent
  ]
})
export class SharedModule {}

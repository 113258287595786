import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LocationFacade } from '@app/modules/location/facade/location.facade';

@Component({
  selector: 'app-desktop-sidebar',
  templateUrl: './desktop-sidebar.component.html',
  styleUrls: ['./desktop-sidebar.component.scss']
})
export class DesktopSidebarComponent {
  isAssetDetailsView: boolean = false;
  paths = ['/live', '/history'];
  constructor(public locationFacade: LocationFacade, private router: Router) {
    this.isAssetDetailsView = this.paths.some(path => this.router.url.includes(path));
  }
}

import { FilterLatLng, SortAttribute, SortOrder } from '@app/store/filters/models/filters.model';
import { LatLng } from 'leaflet';

export enum DeviceType {
  ZTRAK = 'zTrak'
}

export interface Asset {
  assetId: string;
  companyId?: string;
  divisionId?: string;
  legacyAccount?: string;
  latitude?: number;
  longitude?: number;
  speed?: number;
  odometer?: number;
  powerOn?: boolean;
  heading?: number;
  assetType?: string; // TODO: appears unused - can we remove from API response?
  stateOfCharge?: number;
  geoEventTs?: string;
  idling?: boolean;
  batteryPct?: number; // TODO appears unused - can we remove from API response? (Or cut ticket to use it?)
  ztrakBatteryCondition?: number;
  assetName?: string;
  make?: string;
  model?: string; // TODO appears unused - can we remove from API response? (Or cut ticket to use it?)
  homeLocationId?: string;
  // Note: homeLocation isn't an API response property, but is set instead by selected asset service for display of the location name in expandable details.
  // Maybe TODO: move into ViewableAsset interface so this interface represents only the API response?
  homeLocation?: string;
  externalId?: string;
  vin?: string;
  licenseNumber?: string;
  powerUnitNumber?: string;
  fuelType?: string;
  gpssn?: string;
  subtype?: string;
  type?: string;
  driverProfileId?: string;
  driverFirstName?: string;
  driverLastName?: string;
  driverChangeSource?: string;
  driverEventTs?: string;
  connectionIssue?: boolean;
  // Note: We don't currently get these from API, but need to leave in model for dependent logic
  openEvents?: OpenEvent[];
}

/*
 * Contains properties of the Assets POST body which might be modified by user using the UI
 * ex: filters, sorting, search
 */
export interface PostBodyConfig {
  companyId?: string;
  assetIds?: Array<string>;
  divisionIds?: Array<string>;
  powerOn?: boolean;
  sortBy?: SortAttribute;
  sortOrder?: SortOrder;
  searchTerms?: Array<string>;
  geojson?: string;
  pageSize?: number;
  offset?: number;
  northEast?: FilterLatLng;
  southWest?: FilterLatLng;
}

/*
 * Extends PostBodyConfig to include additional properties we use in POST body but which user does not influence
 * through use of the UI
 */
export interface PostBody extends PostBodyConfig {
  circle?: Array<number>;
}

export interface AssetsCountResult {
  count: number;
}

// TODO: this should eventually be deprecated once we no longer use store for assets, and once autocomplete doesn't need it in any way
export interface AssetsParams {
  divisionIds?: string[];
  // 'accountDivisions' is not a location-api param. however, in the live-asset-query-service, it is used to pass into
  // the service what account-level divisions need to be passed into the DivisionIds field
  accountDivisions?: Array<string>;
  assetIds?: Array<string>;
  ztrak?: boolean;
  requireDevice?: boolean;
  powerOn?: boolean;
  idling?: boolean;
  minSpeed?: number;
  maxSpeed?: number;
  sortBy?: SortAttribute;
  sortOrder?: SortOrder;
  circle?: Array<number>;
  polygon?: Array<number>;
  companyId?: string;
  divisionId?: string;
  legacyAccount?: string;
  driverProfileId?: string;
  pageSize?: number;
  offset?: number;
  detailedInfo?: boolean;
  active?: boolean;
  searchTerms?: Array<string>;
  geojson?: string;
  northEast?: FilterLatLng;
  southWest?: FilterLatLng;
}

export interface GetAssetsResult {
  assets?: Asset[];
  nextPageOffset?: number;
}

// Asset Open Event model
export interface OpenEvent {
  id?: string;
  title?: string;
  productId?: string;
  description?: string;
  severity?: EventSeverity;
  eventTimeStamp?: string;
}

// GTCxEventData model

export enum EventSeverity {
  INFO = 3,
  WARNING = 2,
  CRITICAL = 1
}

export enum EventStatus {
  OPEN = 'open',
  CLOSED = 'closed',
  INSTANT = 'instant'
}

export interface EventMetadata {
  key: string;
  label: string;
  value?: any;
}

export interface EventMultimedia {
  url: string;
  created: string;
  contentType: string;
}

export interface GTCxData {
  id: string;
  productId: string;
  dataType: string;
  parentId: string;
  startTime: string;
  endTime: string;
  title: string;
  description: string;
  severity: EventSeverity; // 1 is high, 3 is low
  eventStatus: EventStatus;
  urlName: string;
  location: LatLng;
  metadata: EventMetadata[];
  multimedia: EventMultimedia[];
}

export interface GTCxEventData {
  id: string;
  assetId: string;
  dataType: string;
  location: LatLng;
  severity: EventSeverity;
  startTime?: string;
  jsonData?: GTCxData;
  openEventInsertTs: string;
}

export interface GetEventsResult {
  events?: GTCxEventData[];
  nextPageToken?: string;
}

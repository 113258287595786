import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

// a service to hold the current company id for reference throughout the app in a singleton
export class CurrentCompanyService {
  constructor() {}

  // we set via next() after sidenav bootstrap, and from asset by id response when going by link directly to details/history view
  currentCompanyId$: BehaviorSubject<string> = new BehaviorSubject(null);

  getCurrentCompanyId() {
    return this.currentCompanyId$;
  }
}

import * as filterActions from '@app/store/filters/actions/filters.actions';
import * as searchFiltersActions from '@app/store/filters/actions/search-filters.actions';
import {
  EntityType,
  Filter,
  FiltersState,
  initialFilterState,
  ToggleFilterType
} from '@app/store/filters/models/filters.model';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { processDivisionsFromCompanyApi } from '@app/modules/shared/utilities/utilities';
import { createReducer, on } from '@ngrx/store';
import * as FilterActions from '../actions/filters.actions';
import { DropdownOption } from '@zonar-ui/searchable-dropdown';

export const filtersFeatureKey = 'filters';

export const initialFiltersState: FiltersState = {
  filter: initialFilterState,
  companies: [],
  companiesLoadingState: ResourceLoadState.INITIAL,
  companiesError: null,
  companiesParams: {},
  divisions: [],
  divisionsLoadingState: ResourceLoadState.INITIAL,
  divisionsError: null,
  divisionsParams: {},
  locations: [],
  locationsLoadingState: ResourceLoadState.INITIAL,
  locationsError: null,
  locationsParams: {},
  assetIds: [],
  defaultFilter: initialFilterState,
  // this boolean prevents a limited user from seeing chips appear by default
  showFilterChipsForLimitedUser: false,
  zone: null
  //zonesLoadingState: ResourceLoadState.INITIAL
};

export const reducer = createReducer(
  initialFiltersState,

  on(FilterActions.removeFilter, (state: FiltersState, { filter }) => {
    const newState = { ...state };
    switch (filter.type) {
      case EntityType.COMPANY:
        return {
          ...state,
          filter: { ...state.defaultFilter },
          divisions: initialFiltersState.divisions,
          divisionsLoadingState: initialFiltersState.divisionsLoadingState,
          locations: []
        };
      case EntityType.DIVISION:
        const newDivs = newState.filter.divisions.filter(item => item.id !== filter.id);
        const newLocs = newDivs.length !== 1 ? [] : newState.filter.locations;
        const newLocOptions = newDivs.length !== 1 ? [] : newState.locations;
        newState.filter = { ...newState.filter, divisions: newDivs, locations: newLocs };
        newState.locations = newLocOptions;
        return { ...newState };
      case EntityType.LOCATION:
        const locs = newState.filter.locations.filter(item => item.id !== filter.id);
        newState.filter = { ...newState.filter, locations: locs };
        return { ...newState };
      case EntityType.DRIVER:
        newState.filter = { ...newState.filter, driver: null };
        return newState;
      case EntityType.SEARCH_TERM:
        const newSearchTerms = newState.filter.searchTerms.filter(st => st !== filter.id);
        newState.filter = { ...newState.filter, searchTerms: [...newSearchTerms] };
        return { ...newState };

      case EntityType.ZONE:
        newState.filter = { ...newState.filter, zone: null };
        return newState;

      case EntityType.TOGGLE:
        switch (filter.id) {
          case ToggleFilterType.POWER_ON:
            newState.filter = { ...newState.filter, powerOn: false };
            return { ...newState };
        }
    }
  }),

  on(FilterActions.clearFilters, state => {
    const divs = state.defaultFilter.company ? state.divisions : initialFiltersState.divisions;
    const locs =
      state.defaultFilter.divisions && state.defaultFilter.divisions.length === 1
        ? state.locations
        : initialFiltersState.locations;
    return {
      ...state,
      divisions: divs,
      locations: locs,
      filter: state.defaultFilter,
      query: '',
      showFilterChipsForLimitedUser: false
    };
  }),

  on(FilterActions.applyFilters, (state: FiltersState, { filterArgs }) => {
    const newFilter = <Filter>{};
    let initialCompany = <DropdownOption>{};
    if (state.companies.length > 0) {
      // if company ID is defined in filterArgs, set initialCompany based on the query param
      if (filterArgs?.company?.length > 0) {
        initialCompany = state.companies.find(c => c.value === filterArgs.company);
        // else if the company is defined in the filter
      } else if (state?.filter?.company?.id) {
        initialCompany = state.companies.find(c => c.value === state.filter.company.id);
        // if all else fails, set to first company in state
      } else {
        initialCompany = state.companies[0];
      }
    }
    // I don't think we need this until we have multi-driver
    // at which point we'll need to filter the set of drivers
    // the same as we're currently filtering divisions & locations
    // if (filterArgs.driver) {
    //   newFilter.driver = filterArgs.driver;
    // }
    if (filterArgs?.searchTerms?.length) {
      newFilter.searchTerms = [...filterArgs.searchTerms];
    }
    newFilter.powerOn = filterArgs.powerOn;
    newFilter.company = { id: initialCompany?.value, name: initialCompany?.title, type: EntityType.COMPANY };
    if (state.divisions.length > 0 && filterArgs.divisions) {
      newFilter.divisions = filterArgs.divisions.reduce((a: any[], c: string) => {
        const div = state.divisions.find(d => d?.id === c);
        const { legacyLocationsForLimitedUser } = div;
        return [
          ...a,
          {
            id: div.id,
            name: div.legacyAccountCode || div.name,
            type: EntityType.DIVISION,
            legacyType: div.type,
            legacyLocationsForLimitedUser
          }
        ];
      }, []);
    }
    newFilter.locations = filterArgs.locations?.reduce((a: any[], c: string) => {
      const loc = state.locations.find(l => l.id === c);
      if (loc) {
        return [...a, { id: loc.id, name: loc.name, type: EntityType.LOCATION }];
      }
      return a;
    }, []);
    if (filterArgs.divisionIds) newFilter.divisionIds = [...filterArgs.divisionIds];
    if (filterArgs.assetIds) newFilter.assetIds = [...filterArgs.assetIds];
    return { ...state, showFilterChipsForLimitedUser: true, filter: { ...state.filter, ...newFilter } };
  }),

  on(FilterActions.applySorting, (state: FiltersState, { sorting }) => {
    return { ...state, filter: { ...state.filter, ...sorting } };
  }),

  on(searchFiltersActions.getCompanies, (state: FiltersState, action) => {
    const { params } = action;
    return {
      ...state,
      companiesLoadingState: ResourceLoadState.LOADING,
      companiesError: null,
      companiesParams: params
    };
  }),

  on(searchFiltersActions.saveCompanies, (state: FiltersState, action) => {
    const { companies } = action;
    const existingCompanies = state.companies ? state.companies : [];
    return {
      ...state,
      companies: existingCompanies.concat(companies),
      companiesLoadingState: ResourceLoadState.LOADING
    };
  }),

  on(searchFiltersActions.getCompaniesSuccess, (state: FiltersState) => {
    return {
      ...state,
      companiesLoadingState: ResourceLoadState.LOAD_SUCCESSFUL
    };
  }),

  on(searchFiltersActions.getCompaniesFailure, (state: FiltersState, action) => {
    const { error } = action;
    return {
      ...state,
      companiesLoadingState: ResourceLoadState.LOAD_FAILURE,
      companiesError: error.error?.message || error.message
    };
  }),

  on(searchFiltersActions.getSearchFiltersDivisions, (state: FiltersState, action) => {
    const { params } = action;
    return {
      ...state,
      divisionsLoadingState: ResourceLoadState.LOADING,
      divisionsError: null,
      divisionsParams: params
    };
  }),

  on(searchFiltersActions.getSearchFiltersDivisionsSuccess, (state: FiltersState, action) => {
    const { divisions } = action;
    // Filtering out invalid data.
    const newDivs = processDivisionsFromCompanyApi(divisions);
    return {
      ...state,
      divisions: newDivs,
      divisionsLoadingState: ResourceLoadState.LOAD_SUCCESSFUL
    };
  }),

  on(searchFiltersActions.getSearchFiltersDivisionsFailure, (state: FiltersState, action) => {
    const { error } = action;
    return {
      ...state,
      divisionsLoadingState: ResourceLoadState.LOAD_FAILURE,
      divisionsError: error.error?.message || error.message
    };
  }),

  on(searchFiltersActions.clearSearchFiltersDivisions, (state: FiltersState) => ({
    ...state,
    divisions: initialFiltersState.divisions,
    divisionsLoadingState: initialFiltersState.divisionsLoadingState
  })),

  on(searchFiltersActions.getSearchFiltersLocations, (state: FiltersState, action) => {
    const { params } = action;
    return {
      ...state,
      locations: [],
      locationsLoadingState: ResourceLoadState.LOADING,
      locationsError: null,
      locationsParams: params
    };
  }),

  on(searchFiltersActions.saveSearchFiltersLocations, (state: FiltersState, action) => {
    const { locations } = action;
    const existingLocations = state.locations ? state.locations : [];
    const newLocations = processDivisionsFromCompanyApi(locations);
    return {
      ...state,
      locations: existingLocations.concat(newLocations),
      locationsLoadingState: ResourceLoadState.LOADING
    };
  }),

  on(searchFiltersActions.getSearchFiltersLocationsSuccess, (state: FiltersState) => {
    return {
      ...state,
      locationsLoadingState: ResourceLoadState.LOAD_SUCCESSFUL
    };
  }),

  on(searchFiltersActions.getSearchFiltersLocationsFailure, (state: FiltersState, action) => {
    const { error } = action;
    return {
      ...state,
      locationsLoadingState: ResourceLoadState.LOAD_FAILURE,
      locationsError: error.error?.message || error.message
    };
  }),

  on(searchFiltersActions.clearSearchFiltersLocations, (state: FiltersState) => {
    return { ...state, filter: { ...state.filter, locations: [] }, locations: [] };
  }),

  on(searchFiltersActions.resetSearchFiltersState, (state: FiltersState) => {
    return { ...initialFiltersState, filter: state.defaultFilter, defaultFilter: state.defaultFilter };
  }),
  on(filterActions.setZone, (state: FiltersState, { zone }) => {
    const selectedZone = { id: zone?.id, name: zone?.name, type: EntityType.ZONE };
    const filter = { ...state.filter, zone: selectedZone };
    state = { ...state, filter: { ...filter }, zone: zone };
    return state;
  }),

  on(filterActions.setDefaultFilter, (state: FiltersState, { filterArgs }) => {
    const filteredDivs = state.divisions.filter(div => filterArgs.divisions.find((d: string) => d === div.id));
    const filteredLocs = state.locations.filter(loc => filterArgs.locations.find((l: string) => l === loc.id));

    if (state.companies && state.companies.length && !state.filter.defaultFilterSet) {
      const filteredCompany = state.companies.find(comp => filterArgs.company === comp?.value);
      const newFilter: Filter = {
        powerOn: filterArgs.powerOn,
        sortOrder: filterArgs.sortOrder,
        sortAttribute: filterArgs.sortAttribute,
        search: filterArgs.search,
        company: { id: filteredCompany?.value, name: filteredCompany?.title, type: EntityType.COMPANY },
        driver: null,
        divisions: filteredDivs.map(d => ({
          id: d.id,
          name: d.legacyAccountCode,
          type: EntityType.DIVISION,
          legacyType: d.type,
          legacyLocationsForLimitedUser: d.legacyLocationsForLimitedUser
        })),
        locations: filteredLocs.map(l => ({ id: l.id, name: l.name, type: EntityType.LOCATION })),
        searchTerms: [...filterArgs.searchTerms],
        defaultFilterSet: true,
        zone: state.filter.zone,
        northEast: state.filter.northEast,
        southWest: state.filter.southWest,
        divisionIds: state.filter.divisionIds,
        assetIds: state.filter.assetIds
      };

      return { ...state, defaultFilter: { ...newFilter }, filter: { ...newFilter } };
    } else {
      return { ...state };
    }
  }),

  on(searchFiltersActions.setCompaniesParams, (state: FiltersState, action) => {
    const { params } = action;
    return { ...state, companiesParams: params };
  }),

  on(searchFiltersActions.resetCompaniesParams, (state: FiltersState) => {
    return { ...state, companiesParams: {} };
  }),

  on(filterActions.setCurrentCompany, (state, action) => {
    const { company } = action;
    const companyItem: DropdownOption = { value: company.id, title: company.name };
    return {
      ...state,
      companies: [companyItem],
      companiesParams: {
        q: `id:${company.id}`
      },
      filter: {
        ...state.filter,
        company
      },
      defaultFilter: {
        ...state.defaultFilter,
        company
      }
    };
  })
);

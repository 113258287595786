<div class="map-header-content" fxLayout="column">
    <!-- <ng-container *ngIf="isAssetSelected; else elseBlock"> -->
    <ng-container *ngIf="isAssetSelected">
        <app-selected-asset-navigation 
            (closeButtonClicked)="closeButtonClicked()"
            [asset]="asset"
            [subContextView]="subContextView"
            (subContextChange)="handleSubContextChange($event)"
        ></app-selected-asset-navigation>
    </ng-container>

    <!-- ZTT-3664 TODO: deprecate this, it will be in filters bar -->
    <!-- <ng-template #elseBlock>
        <app-asset-search-form (filterClick)="handleFilter()" [hideFilterButton]=" hideFilterButton"></app-asset-search-form>          
    </ng-template>
    <app-map-filter-chip-list [isAssetSelected]="isAssetSelected"></app-map-filter-chip-list> -->
</div>
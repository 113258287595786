import { Component, OnInit, ViewChild } from '@angular/core';
import { FiltersBarSelections } from '@app/services/filters.model';
import { FiltersService } from '@app/services/filters.service';
import { ZuiFiltersBarComponent } from '@zonar-ui/filter';
import { distinctUntilChanged } from 'rxjs';
import { Subject, takeUntil, filter } from 'rxjs';

@Component({
  selector: 'app-filters-bar-container',
  templateUrl: './filters-bar-container.component.html',
  styleUrls: ['./filters-bar-container.component.scss']
})
export class FiltersBarContainerComponent implements OnInit {
  @ViewChild(ZuiFiltersBarComponent) filterComponent: ZuiFiltersBarComponent;

  filterList = [];
  private onDestroy$ = new Subject<void>();
  currentSelectedCompanyId: string;

  constructor(public filtersService: FiltersService) {
    this.filterList = this.filtersService.getFilterList();
  }

  ngOnInit(): void {
    this.filtersService
      .getFiltersState()
      .pipe(
        filter(filters => filters.companyId != null),
        distinctUntilChanged((prev, curr) => prev.companyId === curr.companyId),
        takeUntil(this.onDestroy$)
      )
      .subscribe(filterState => {
        this.currentSelectedCompanyId = filterState.companyId;
        this.filterComponent?.resetFilters();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  handleFiltersBarSelections(selections: FiltersBarSelections) {
    this.filtersService.setStateFromFiltersBar(selections);
  }
}
